<template>
  <div class="container-layout-custom" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row items-center">
        <div class="text-h4">Leaderboard <span class="text-weight-light">for</span></div>
        <div class="row">
          <q-select v-model="topNumber" :options="topNumberOptions" class="q-mx-sm" rounded outlined transition-show="flip-up" transition-hide="flip-down" popup-content-class="bg-lmsBackground primaryText-text"/>
          <q-select v-model="leaderboard" @input="getLeaderboardData" :options="leaderboardOptions" class="q-mx-sm" rounded outlined transition-show="flip-up" transition-hide="flip-down" popup-content-class="bg-lmsBackground primaryText-text" />
          <q-select v-model="leaderboardTimePeriod" @input="getLeaderboardData" :options="leaderboardTimePeriodOptions" class="q-mx-sm" rounded outlined transition-show="flip-up" transition-hide="flip-down" popup-content-class="bg-lmsBackground primaryText-text" />
          <q-select v-model="location" @input="getLeaderboardData" :options="locationOptions" class="q-mx-sm" rounded outlined transition-show="flip-up" transition-hide="flip-down" popup-content-class="bg-lmsBackground primaryText-text" />

        </div>
      </div>
      <div class="row justify-center q-my-lg">
        <q-card v-for="(user, i) in topThree" :key="i" class="q-mx-lg bg-lmsBackground primaryText-text" style="min-width: 250px" :class="`card-${i + 1}`">
          <q-card-section class="row justify-center">
           <q-badge floating class="q-pa-xs q-ma-sm rank">
              <q-icon name="star" size="14px" color="black" class="q-ml-xs q-mr-xs" /> <span class="q-mr-xs">{{ getOrdinal(i + 1) }}</span>
            </q-badge>
          </q-card-section>
          <q-card-section>
            <div class="row justify-center"><q-avatar><img :src="user.avatar || $consts.DEFAULT_AVATAR" /></q-avatar></div>
            <div class="text-subtitle1 col-12 row justify-center">{{ user.name }}</div>
            <div class="text-caption col-12 row justify-center">{{ user.location.toUpperCase() }}</div>
          </q-card-section>
          <q-card-section class="row justify-center">
            <div>{{ user.total }} {{ leaderboard.label }}</div>
          </q-card-section>
        </q-card>
      </div>
      <div class="row justify-center">
        <q-list style="min-width: 568px">
          <q-item v-for="(user, i) in nextTen" :key="i">
            <q-item-section avatar>
              <div class="text-subtitle1">
                {{ getOrdinal(i + 4) }}
              </div>
            </q-item-section>
            <q-item-section avatar>
              <q-avatar><img :src="user.avatar || $consts.DEFAULT_AVATAR" /></q-avatar>
            </q-item-section>
            <q-item-section>
              <div class="text-subtitle2">{{ user.name }}</div>
              <div class="text-caption">{{ user.location }}</div>
            </q-item-section>
            <q-item-section side>
              {{ user.total }} {{ leaderboard.label }}
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>

    <q-dialog v-model="showUserPosition" seamless position="bottom">
      <q-card style="width: 550px" class="bg-lmsBackground primaryText-text">
        <q-card-section class="row items-center justify-center no-wrap">
            <div class="text-weight-bold text-center">{{ userPosition }}</div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { date } from 'quasar'
// import MediaSelect from 'components/MediaSelect'
export default {
  name: 'LeaderboardsPage',
  components: { },
  data () {
    return {
      boardData: [],
      location: { label: 'Company', value: 'Company' },
      locationOptions: [],
      leaderboard: { label: 'Total Points', value: 'pointsLeaderboard' },
      leaderboardOptions: [
        { label: 'Logins', value: 'loginLeaderboard' },
        { label: 'Videos Completed', value: 'videosCompletedLeaderboard' },
        { label: 'Minutes Watched', value: 'minutesWatchedLeaderboard' },
        { label: 'Total Points', value: 'pointsLeaderboard' }
      ],
      leaderboardTimePeriod: { label: 'Over last 30 days', value: 30 },
      leaderboardTimePeriodOptions: [
        { label: 'Over last 30 days', value: 30 },
        { label: 'Over last 90 days', value: 90 },
        { label: 'Over last year', value: 365 },
        { label: 'All Time', value: 'allTime' }
      ],
      topNumber: { label: 'Top 25', value: 25 },
      topNumberOptions: [
        { label: 'Top 10', value: 10 },
        { label: 'Top 25', value: 25 },
        { label: 'Top 50', value: 50 },
        { label: 'Top 100', value: 100 }
      ],
      showUserPosition: false
    }
  },
  computed: {
    startDate: function () {
      if (this.leaderboardTimePeriod.value === 'allTime') {
        return '2000/01/01'
      }
      return date.formatDate(date.subtractFromDate(Date.now(), { days: this.leaderboardTimePeriod.value }), 'YYYY/MM/DD')
    },
    endDate: function () {
      return date.formatDate(Date.now(), 'YYYY/MM/DD')
    },
    topThree: function () {
      return this.boardData.slice(0, 3)
    },
    nextTen: function () {
      return this.boardData.slice(3, this.topNumber.value)
    },
    userPosition () {
      if (!this.boardData) return null
      let currentUser = this.boardData.findIndex(user => user.user_uid === this.$store.state.auth.user.uid)

      return currentUser >= 0 ? `You're currently ranked #${currentUser + 1}` : 'You are not ranked based on the options you selected.'
    }
  },
  methods: {
    getOrdinal (n) {
      var s = ['th', 'st', 'nd', 'rd'], v = n % 100
      return n + (s[(v - 20) % 10] || s[v] || s[0])
    },
    async getLeaderboardData () {
      this.$q.loading.show()

      try {
        let res = await this.$axios.get(`${this.$consts.GET_REPORT}/${this.leaderboard.value}?startDate=${this.startDate}&endDate=${this.endDate}`)
        this.filterData(res)
        this.showUserPosition = true
        this.$q.loading.hide()
      } catch (e) {
        this.$q.loading.hide()
        this.$failureMsg(`Sorry, couldn't load leaderboard`)
      }
    },
    filterData (data) {
      if (this.location.value === 'Company') {
        this.boardData = data
      } else {
        this.boardData = data.filter(item => item.location === this.location.label)
      }
    }

  },
  async created () {
    this.getLeaderboardData()
    this.locationOptions = [{ label: 'Company', value: 'Company' }, ...this.$store.getters['locations/list']]
  }
}
</script>

<style lang="stylus">
  .card-1
    border-top: 5px solid #F7C948;

    .rank
      background-color #F7C948
      color #222222
  .card-2
    border-top: 5px solid #9AA5B1;

    .rank
      background-color #9AA5B1
      color #222222
  .card-3
    border-top: 5px solid #C99A2E;

    .rank
      background-color #C99A2E
      color #222222
</style>
